document.addEventListener("DOMContentLoaded", function () {
  if (document.body.classList.contains("js-white-paper-page")) {
    document
      .querySelector(".js-download-form")
      .addEventListener("submit", function (e) {
        e.preventDefault();

        const unindexed_array = $(".js-download-form").serializeArray();
        const indexed_array = {};

        $.map(unindexed_array, function (n, i) {
          indexed_array[n["name"]] = n["value"];
        });

        const axios = require("axios");
        axios({
          method: "post",
          url: "api/WhitePaper",
          responseType: "blob",
          data: indexed_array,
        })
          .then(function (response) {
            const { data, headers } = response;
            const blob = new Blob([data], { type: headers["content-type"] });
            const dom = document.createElement("a");
            const url = window.URL.createObjectURL(blob);
            dom.href = url;
            dom.download = decodeURI(getfilename(headers));
            dom.style.display = "none";
            document.body.appendChild(dom);
            dom.click();
            dom.parentNode?.removeChild(dom);
            window.URL.revokeObjectURL(url);
            location.href = "contact-success.html";
          })
          .catch(function (error) {
            invokeApi();
          });
      });
  }

  function getfilename(headers) {
    let filename = "";
    const disposition = headers["content-disposition"];
    if (disposition && disposition.indexOf("attachment") !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }
    return filename;
  }

  function invokeApi() {
    // TODO 請在此串接 DB
    if (localStorage.getItem("pixis_lang") === "tw") {
      alert(
        "很抱歉，為了帶給您最好的服務，我們正在努力更新系統中，請稍後再試。"
      );
    } else {
      alert(
        "Sorry, in order to bring you the best service, we are working hard to update the system, please try again later."
      );
    }
  }
});
