document.addEventListener("DOMContentLoaded", function () {
  if (document.body.classList.contains("js-contact-page")) {
    document
      .querySelector(".js-contact-form")
      .addEventListener("submit", function (e) {
        console.log("!!!!!!");
        e.preventDefault();

        const unindexed_array = $(".js-contact-form").serializeArray();
        const indexed_array = {};

        $.map(unindexed_array, function (n, i) {
          indexed_array[n["name"]] = n["value"];
        });

        const axios = require("axios");
        axios({
          method: "post",
          url: "api/ContactUs",
          data: indexed_array,
        })
          .then(function (response) {
            location.href = "contact-success.html";
          })
          .catch(function (error) {
            console.log(error);
            invokeApi();
          });
      });
  }

  function invokeApi() {
    // TODO 請在此串接 DB
    if (localStorage.getItem("pixis_lang") === "tw") {
      alert(
        "很抱歉，為了帶給您最好的服務，我們正在努力更新系統中，請稍後再試。"
      );
    } else {
      alert(
        "Sorry, in order to bring you the best service, we are working hard to update the system, please try again later."
      );
    }
  }
});
